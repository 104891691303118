import React,{useEffect,useState,useRef} from 'react'

import { useDispatch,useSelector } from 'react-redux';
import AdminTemplate from '../Components/Template/AdminTemplate';
import { Link } from 'react-router-dom';
//import DashboardSchool from './SchoolAdmin/DashboardSchool';

//import SuperadminDashboard from './SuperAdmin/SuperadminDashboard';
//import { useCreateSchoolMutation } from '../Services/modules/school';

function MobileAppDashboard() {
  const isSuperAdmin = useSelector(state => state.userData.user_data.role == "super_admin" ? true : false);
const MobileAppDashboardScreen=()=>{
  
    if(isSuperAdmin)
    { 
        return (
      
       <>
        <div className="dashboard-container">
       
      <div className="card">
      <Link to='/podcastmobile'  style={{textDecoration:'none'}}>
        <h3>Upload Podcast</h3>
        </Link>
      </div>
      
      <div className="card">
      <Link to='/podcastmeditation'  style={{textDecoration:'none'}}>
        <h3>Upload Guided Meditation</h3>
        </Link>
       
      </div>
     
      <div className="card">
      <Link to='/addshorts'  style={{textDecoration:'none'}}>
        <h3>Upload Shorts</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/addyoutubevideos'  style={{textDecoration:'none'}}>
        <h3>Upload Youtube Videos</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/addhomework'  style={{textDecoration:'none'}}>
        <h3>Upload Homework</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/words'  style={{textDecoration:'none'}}>
        <h3>Add Words</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/idioms'  style={{textDecoration:'none'}}>
        <h3>Add Idioms</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/ieltsdate'  style={{textDecoration:'none'}}>
        <h3>Add IELTS Test Links</h3>
        </Link>
      </div>
      <div className="card">
      <Link to='/addMoodTestVideos'  style={{textDecoration:'none'}}>
        <h3>Add Mood Test Videos</h3>
        </Link>
      </div>

      

      
     


      
     
    </div>
       </>
      
     )}
    
  
}
  if(isSuperAdmin)
 { 
    return (
   
     <AdminTemplate>
      {MobileAppDashboardScreen()}
     </AdminTemplate>
   
  )}
 
}

export default MobileAppDashboard
import React from 'react'
import { useSelector } from 'react-redux'
import { 
    Route,
    Routes,     
    Navigate,
    Outlet, } from 'react-router-dom'
import Course from './Pages/Course'
import CourseContent from './Pages/CourseContent'
import CoursePackage from './Pages/CoursePackage'
//import ActiveTeachingStaff from './Pages/ActiveTeachingStaff'
import Dashboard from './Pages/Dashboard'
import Login from './Pages/Login'
import Notes from './Pages/Notes'
import Books from './Pages/Books'
import CreateStudent from './Pages/CreateStudent'
import CreateExistingStudent from './Pages/CreateExistingStudent'
import Testimonials from './Pages/Testimonials'
import OtherInfoYoutube from './Pages/OtherInfoYoutube'
import OtherInfoQuotes from './Pages/OtherInfoQuotes'
import Blog from './Pages/Blog'
import Podcast from './Pages/Podcast'
import MobileAppDashboard from './Pages/MobileAppDashboard'
import PodcastMobile from './Pages/PodcastMobile'
import PodcastMeditation from './Pages/PodcastMeditation'
import AddYoutubeVideos from './Pages/AddYoutubeVideos'
import Shorts from './Pages/Shorts'
import OtherInfoWord from './Pages/otherInfoWord'
import OtherInfoHomework from './Pages/OtherInfoHomework'
import IeltsDate from './Pages/IeltsDate'
import IeltsLinks from './Pages/IeltsLinks'
import OtherInfoIdioms from './Pages/OtherInfoIdioms'
import Reports from './Pages/Reports'
import ReportRecordedCourseStudents from './Pages/ReportRecordedCourseStudents'
import AddMoodTestVideos from './Pages/AddMoodTestVideos'

function AppRoutes() {
  const isLoggedin = useSelector(state => state.userData.user_data == null ? false : true);
  const checkdata = useSelector(state => state.userData.user_data);
 
    const ProtectedRoute = ({
        isAllowed,
        redirectPath = '/',
        children,
      }) => {
        if (!isAllowed) {
          return <Navigate to={redirectPath} replace />;
        }
      
        return children ? children : <Outlet />;
      };
  return (
    <>
   
    <Routes>
        <Route index element={<Login/>}/>
        <Route element={<ProtectedRoute isAllowed={isLoggedin} />}>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path="/mobileappdashboard" element={<MobileAppDashboard/>}/>
        <Route path="/podcastmobile" element={<PodcastMobile/>}/>
        <Route path="/podcastmeditation" element={<PodcastMeditation/>}/>
        <Route path="/addyoutubevideos" element={<AddYoutubeVideos/>}/>
        <Route path="/addMoodTestVideos" element={<AddMoodTestVideos/>}/>

        <Route path="/addshorts" element={<Shorts/>}/>
        <Route path="/addhomework" element={<OtherInfoHomework/>}/>

        <Route path="/course" element={<Course/>}/>
        <Route path="/coursecontent" element={<CourseContent/>}/>
        <Route path="/coursepackage" element={<CoursePackage/>}/>
        <Route path="/notes" element={<Notes/>}/>
        <Route path="/books" element={<Books/>}/>
        <Route path="/testimonials" element={<Testimonials/>}/>

        <Route path="/newadmission" element={<CreateStudent/>}/>
        {/* <Route path="/oldadmission" element={<CreateExistingStudent/>}/> */}
        <Route path="/reportdashboard" element={<Reports/>}/>  
        <Route path="/studentadmission" element={<CreateExistingStudent/>}/>  

        <Route path="/recordedcoursestudnets" element={<ReportRecordedCourseStudents/>}/>  

             
        <Route path="/youtubeinfo" element={<OtherInfoYoutube/>}/>
        <Route path="/quotes" element={<OtherInfoQuotes/>}/>
        <Route path="/words" element={<OtherInfoWord/>}/>
        <Route path="/idioms" element={<OtherInfoIdioms/>}/>

        <Route path="/blogs" element={<Blog/>}/>
        <Route path="/podcast" element={<Podcast/>}/>

        <Route path="/ieltsdate" element={<IeltsDate/>}/>
        <Route path="/ieltslink" element={<IeltsLinks/>}/>
       
        </Route>
    </Routes>
    </>
  )
}

export default AppRoutes
import { api } from '../../api'
import addYoutubeMoodTestVideos from './addYoutubeMoodTestVideos'

import addYoutubeVideos from './addYoutubeVideos'
import deleteYoutubeVideo from './deleteYoutubeVideo'
import getAllMoodTestVideos from './getAllMoodTestVideos'
import getAllYoutubeVideos from './getAllYoutubeVideos'

export const youtubeVideosApi = api.injectEndpoints({
    endpoints: build => ({
        AddYoutubeVideos: addYoutubeVideos(build),
        AddYoutubeMoodTestVideos: addYoutubeMoodTestVideos(build),

        GetAllYoutubeVideos:getAllYoutubeVideos(build)     ,
        GetAllMoodTestVideos:getAllMoodTestVideos(build)     ,

        DeleteYoutubeVideo:   deleteYoutubeVideo(build) 
       
    }),
    overrideExisting: true,
    //providesTags: ['Research']
})
export const {
    useAddYoutubeVideosMutation, 
    useGetAllYoutubeVideosQuery,
    useGetAllMoodTestVideosQuery,
    useDeleteYoutubeVideoMutation,
    useAddYoutubeMoodTestVideosMutation,
  
} = youtubeVideosApi

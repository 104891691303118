import React, { useState,useEffect } from 'react'
import { Grid, Paper,Container, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import AdminTemplate from '../Components/Template/AdminTemplate'
import TextBox from '../Components/InputFields/TextBox'
import SubmitButton from '../Components/InputFields/SubmitButton'
import { useCreateCourseMutation,useGetAllCoursesQuery } from '../Services/modules/course';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from '../Components/DataTable'
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Select, Input, Table, Button, Icon,Space } from "antd";
import { useDispatch } from 'react-redux';

import { useNavigate  } from "react-router-dom";
import { useCreateNotesMutation, useDeleteNotesMutation, useGetAllNotesQuery } from '../Services/modules/notes'
import { Config } from '../Config'
import { useAddYoutubeVideosMutation, useDeleteYoutubeVideoMutation, useGetAllYoutubeVideosQuery } from '../Services/modules/youtubeVideos'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function AddYoutubeVideos() {
  const navigate=useNavigate ()
  const dispatch = useDispatch();
  const[totalCount,setTotalCount]=useState(0)    
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10 }); // Initial pagination info
  const[showDialogDetails,setShowDialogDetails]=useState(false)
  const[viewVideoDetails,setViewVideoDetails]=useState({})
  const[showDialogDelete,setShowDialogDelete]=useState(false)
const[videoId,setVideoId]=useState("")
const{data:videosData=[],isSuccess:isVideoSuccess,isLoading:isVideoLoading,error:isVideoError}=
useGetAllYoutubeVideosQuery(
 { pageIndex: pageInfo.current - 1, // Adjust pageIndex for zero-based indexing
   pageSize: pageInfo.pageSize}
)
  const [AddYoutubeVideo, { data, isSuccess, isLoading, isError, error }] = useAddYoutubeVideosMutation();
  const [DeleteVideo, { data:deleteData, isSuccess:deleteSuccess, isLoading:deleteLoading, isError:deleteIsError, error:deleteError }] = useDeleteYoutubeVideoMutation();
 
   const columns = [ 
   
    { 
      key: "caption", 
      title: "Caption", 
      dataIndex: "caption", 
      }, 
   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">    
        <a onClick={()=>showDetails(record)} style={{color: "blue"}}><RemoveRedEyeIcon style={{fontSize:'medium'}}/> </a> 

        <a onClick={()=>openDeleteAlertDialog(record)} style={{color:"green"}}>
          <DeleteIcon style={{fontSize:'medium'}}/> 
          </a>    
         
        </Space>
      ),
    },
    
    ] 
    const [videoCaption, setVideoCaption] = useState('');
    const [videoLink, setVideoLink] = useState('');

    const[allVideos,setAllVideos]=useState([])
    useEffect(()=>{
      console.log("notes data",videosData)
    if(isVideoSuccess)
    {
      setTotalCount(videosData.totalRows)
    
      setAllVideos(videosData.data)
    }
    },[videosData])

const showDetails=(record)=>{
  setViewVideoDetails(record)
  setShowDialogDetails(true);
 }


    const handleCaptionChange = (event) => {
        setVideoCaption(event.target.value);
      };
      const handleLinkChange = (event) => {
        setVideoLink(event.target.value);
      };
    
      const closeDetailDialog=()=>{
  
        setShowDialogDetails(false)
    
       }
       const openDeleteAlertDialog=(record)=>{
        setVideoId(record._id)
        setShowDialogDelete(true)
           }
       const deleteVideo=()=>{
        DeleteVideo({videoId})
    }
    function onSubmit (){    
      //  console.log("notes file",file)    
      AddYoutubeVideo({embed_link:videoLink,caption:videoCaption,video_for:"normal"})

     }
     const handlePageChange = (pagination) => {
      setPageInfo({
          current: pagination.current,
          pageSize: pagination.pageSize,
      });
  };
  const closeDialog=()=>{
  
    setShowDialogDelete(false)

   }
   useEffect(()=>{
    if(deleteSuccess)
    {
        console.log("videoData",deleteData)
        toast.success(deleteData.message)
        setShowDialogDelete(false)
        setVideoCaption("")
        setVideoLink("")
    }
},[deleteData])
    const AddYoutubeVideosScreen=()=>{
        return(
          <>
           <ToastContainer />
           <Dialog
        open={showDialogDelete}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Record"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Do you really want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button onClick={deleteVideo} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
           <Dialog
        open={showDialogDetails}
        onClose={closeDetailDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Testimonials Details"}
        </DialogTitle>
        <DialogContent>
      
        <Grid item xs={12} md={12} lg={12}>
        caption: {viewVideoDetails.caption}
         </Grid>
       
       
         <Grid item xs={12} md={12} lg={12}>
         <iframe width="560" height="315" 
        src={`https://www.youtube.com/embed/${viewVideoDetails.video_id}`}                    
        frameborder="0" allowfullscreen></iframe>  
         </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDetailDialog}>Close</Button>
        
        </DialogActions>
      </Dialog>
           <Container alignItems="center" sx={{mt:5}}>
      <ToastContainer />
            <Grid
           container
           spacing={5}
           direction="row"
           alignItems="center"
           justify="center"
           //style={{ minHeight: '100vh' }}
         >
                  <Grid item xs={12} md={5} lg={5}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
        <div>         
          <TextBox             
             label={"Video Link"}
             id={"videoLink"}
             name={"videoLink"}
             autoFocus={true}                         
            value={videoLink}  
            isSelect={false}      
            onChange={handleLinkChange}
            />        
        </div>
        <div>         
          <TextBox             
             label={"Video Caption"}
             id={"videoCaption"}
             name={"videoCaption"}
             autoFocus={false}                         
            value={videoCaption}  
            isSelect={false}      
            onChange={handleCaptionChange}
            />        
        </div>
      
       
            <SubmitButton 
              caption={"Save"}             
              button_color={'green'}
              onClick={()=>onSubmit()}
              />
                         </Paper>
                  </Grid>
                  <Grid item xs={12} md={7} lg={7}>
                  <Paper
                           sx={{
                             p: 2,
                             display: 'flex',
                             flexDirection: 'column',
                             height: "auto",
                           }}
                         >
                         
                            <DataTable 
                  columns={columns}
                  Data={allVideos}
                  totalCount={totalCount}
                  handleChange={handlePageChange}
                  
                  />
                       
                         </Paper>
                  </Grid>
         
                 </Grid>
                 </Container>
          </>
         
        )
    }
  return (
   <AdminTemplate>
    {AddYoutubeVideosScreen()}
   </AdminTemplate>
  )
}

export default AddYoutubeVideos
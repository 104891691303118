
//new code from chatgpt with refresh token logic
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Config } from '../Config';
//import { logout, setTokens } from '../features/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: Config.API_URL_LOCAL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().userData.user_data?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Attempt to refresh the access token
    const refreshResult = await baseQuery(
      {
        url: '/auth/refresh',
        method: 'POST',
        body: { refreshToken: api.getState().userData.user_data?.refreshToken },
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      // Store new tokens in state
     // api.dispatch(setTokens(refreshResult.data));

      // Retry the original request with the new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Logout if refresh token is invalid
     // api.dispatch(logout());
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});



//Original previous code
// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import { Config } from '../Config'
// export const api = createApi({
//   baseQuery: fetchBaseQuery(
//     { 
//         baseUrl: Config.API_URL_LOCAL, 
//         prepareHeaders: (headers, { getState }) => { 
        
//           if(getState().userData.user_data)
//           {
//           const user = getState().userData.user_data.token       
// headers.set('Authorization', `Bearer ${user}`)
//           }
       
//             return headers
//           }
//  }),
//   endpoints: () => ({}),
// })
